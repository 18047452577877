@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: Titillium Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}

h2 {
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: #212529;
}

p {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #5d6267;
}

input {
  height: 56px;
  margin: 3px 0 19px;
  padding: 22px 16px 11px;
  border-radius: 8px;
  border: solid 1px #5d6267;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #5d6267;
}

input:hover {
  border-color: #4e5fb7;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --bright-yellow: rgba(255, 255, 0, 0.53);
  --cornflower: rgba(121, 132, 243, 0.53);
  --tomato: #e24912;
  --sickly-yellow: #d9db3e;
  --cornflower-image: rgba(121, 132, 243, 0.45);
  --soft-blue: #62a2ef;
  --silver: #ebeded;
  --black: #000000;
  --warm-grey: #989898;
  --black-23: rgba(0, 0, 0, 0.23);
  --white: #f4f2f2;
  --pale-blue: #e8f0fe;
}